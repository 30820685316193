import React from "react"
import { graphql } from "gatsby"
import Helmet from 'react-helmet'
import Header from '../header'



export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
 
    <div>
    <Helmet
          title={frontmatter.title}
          meta={[
            { name: 'description', content: 'Lantmannen' },
            { name: 'keywords', content: 'lantbruk, odlingstävlingar' },
          ]}
        >
          <html lang="en" />
           
          <body data-prop-articlelevel={frontmatter.level} data-widget-host="profile" /> 
          
          
        </Helmet>
        <Header siteTitle={frontmatter.title} articleLevel={frontmatter.level} />
    
    <div id="main-content" className="page-container">
      <div className="InnerpageSection Premium">
        <h1>{frontmatter.title}</h1>
        <h2>{frontmatter.date}</h2>
       
        <div
          className="post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </div>
   </div>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        level
      }
    }
  }
`